<template>
  <div class="z7-item">
    <div class="z-top-left">
      <img class="jisuan" :src="imgUrl" alt="">
    </div>
    <div class="z-top-right">
      <span class="">
        {{ title }}
      </span>
      <div class="right-wdsl">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['imgUrl', 'title', 'value'],
    // imgUrl: {
    //   type: String,
    //   default: ''
    // },
    // title: {
    //   type: String,
    //   default: ''
    // },
    // value: {
    //   type: String,
    //   default: ''
    // },
  
  data () {
    return {

    }
  },
}
</script>
<style lang="scss" scoped>
.z7-item {
  width: 50%;
  display: flex;
  color: #fff;
  font-size: 9px;
  margin-bottom: 8px;

  .z-top-left {
    margin-right: 6px;
  }

  .zu-tp-right {
    .right-wdsl {
      margin-top: 2px;
    }
  }

  .jisuan {
    width: 28px;
    height: 28px;
  }
}</style>