<template>
    <div class="WhitePaper page-default-config">
        <div class="content">
            <div v-for="(item, index) of contents" :key="index" class="cell" :style="$global.moduleShowAnimation(index)">
                <img :src="item" alt="">
            </div>
        </div>
    </div>
</template>


<script>
// import Content1 from "@/assets/img/WhitePaper/1.png"
// import Content2 from "@/assets/img/WhitePaper/2.png"
// import Content3 from "@/assets/img/WhitePaper/3.png"
// import Content4 from "@/assets/img/WhitePaper/4.png"
// import Content5 from "@/assets/img/WhitePaper/5.png"
// import Content6 from "@/assets/img/WhitePaper/6.png"
import c1 from "@/assets/img/baipishu/111.jpg"
import c2 from "@/assets/img/baipishu/222.jpg"
import c3 from "@/assets/img/baipishu/333.jpg"
import c4 from "@/assets/img/baipishu/444.jpg"
import c5 from "@/assets/img/baipishu/555.jpg"
import c6 from "@/assets/img/baipishu/666.jpg"
import c7 from "@/assets/img/baipishu/777.jpg"
import c8 from "@/assets/img/baipishu/888.jpg"
import c9 from "@/assets/img/baipishu/999.jpg"
import c10 from "@/assets/img/baipishu/10.jpg"
import c11 from "@/assets/img/baipishu/11.jpg"
import c12 from "@/assets/img/baipishu/12.jpg"
import c13 from "@/assets/img/baipishu/13.jpg"
import c14 from "@/assets/img/baipishu/14.jpg"
import c15 from "@/assets/img/baipishu/15.jpg"
import c16 from "@/assets/img/baipishu/16.jpg"
import c17 from "@/assets/img/baipishu/17.jpg"
import c18 from "@/assets/img/baipishu/18.jpg"

export default {
    data () {
        return {
            contents: [
                c1,
                c2,
                c3,
                c4,
                c5,
                c6,
                c7,
                c8,
                c9,
                c10,
                c11,
                c12,
                c13,
                c14,
                c15,
                c16,
                c17,
                c18
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.WhitePaper {
    background-color: #fff;

    .content {
        img {
            display: block;
            width: 100%;
            position: relative;
            top: -1px;
        }
    }
}
</style>