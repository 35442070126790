<template>
    <div class="WhitePaper page-default-config">
        <div class="content">
            
            <div v-for="(item, index) of contents" :key="index" class="cell" :style="$global.moduleShowAnimation(index)">
                <!-- <img :src="setImageUrl(index)" alt=""> -->
                <img :src="require(`@/assets/img/WhitePaper/MFT白皮书_${index}.jpg`)" alt="">
            </div>
        </div>
    </div>
</template>


<script>


export default {
    data () {
        return {
            contents: 60
        }
    },
}
</script>

<style scoped lang="scss">
.WhitePaper {
    background-color: #fff;

    .content {
        img {
            display: block;
            width: 100%;
            position: relative;
            top: -1px;
        }
    }
}
</style>