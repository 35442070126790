<template>
    <div class="part3 part">
        <HomeTile class="margin-bottom__20px">
            <!-- {{ $t(`通证分配`) }} -->
            {{ $t('guanwang1.tzfp') }}
        </HomeTile>
        <!-- <div class="part3-title font-size__36px margin-bottom__20px  padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us16`) }}
        </div> -->
        <div class="gongying">
            {{ $t('guanwang1.zgyl') }}&nbsp;1000000000
        </div>

        <div class="bai99">
            <img src="../../../assets/img/redesign/99.png" alt="">
        </div>

        <div class="part3-c">
            <div class="p2-c-box box-1">
                <div class="box-inner--top">{{ $t('guanwang1.zgyl') }}</div>
                <div class="box-inner--bottom">1%</div>
            </div>
            <div class="p3-logo">
                <img src="../../../assets/img/redesign/logo.png" alt="">
            </div>
            <div class="p2-c-box box-1">
                <div class="box-inner--top">流动性</div>
                <div class="box-inner--bottom">99%</div>
            </div>
        </div>
        <!-- <div class="part3-content">
            <img :src="RoundImage" alt="">
        </div> -->
    </div>
</template>


<style scoped lang="scss">
.part3 {
    .gongying {
        margin: 0 auto;
        font-family: Source Han Sans CN;
        font-size: 12px;
        color: #00F6FF;
    }
    .bai99 {
        margin: 0 36px;
        height: 14px;
        margin-top: 15px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .part3-c {
        margin: 0 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .p2-c-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 96px;
            height: 47px;
            padding: 0 9px;
            background-image: url('../../../assets/img/redesign/bgkaobei.png');
            background-repeat: no-repeat;
            background-size: cover;
            box-sizing: border-box;
            text-align: left;
            .box-inner--top {
                font-weight: 400;
                font-size: 10px;
                color: #FFFFFF;
            }
            .box-inner--bottom {
                font-size: 10px;
                color: #02FAFC;
            }
        }
        .p3-logo {
            width: 130px;
            height: 99px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .part3-content {
        padding: 20px;
        overflow: hidden;
        img {
            width: 100%;
            height: 45vh;
            object-fit: contain;
            display: block;
            transform: scale(1.5);
        }
    }
}
</style>

<script>
import HomeTile from '@/components/HomeTitile.vue'
import RoundImage from "@/assets/img/Home/gif/2_100.gif"
export default {
    components: {
        HomeTile
    },
    data() {
        return {
            RoundImage
        }
    }
}
</script>