<template>
    <div class="part part5">
        <!-- <div class="font-size__36px margin-bottom__10px">
            FAQs
        </div> -->
        <HomeTile class="margin-bottom__20px">
            <!-- {{ $t(`通证分配`) }} -->
            {{$t('guanwang1.us30') }}
        </HomeTile>

        <div class="c477">
            <img src="../../../assets/img/redesign/c477.png" alt="" srcset="">
        </div>

        <div class="concat-item">
            <div class="c-name">Email</div>
            <div class="c-text">etf.digital.token@gmail.com</div>
        </div>
        <div class="concat-item">
            <div class="c-name">TG</div>
            <div class="c-text">https://t.me/MFTDigital</div>
        </div>
        <div class="concat-item">
            <div class="c-name">TW</div>
            <div class="c-text">
https://twitter.com/MFTDigital</div>
        </div>
        <div class="concat-item">
            <div class="c-name">OS</div>
            <div class="c-text">
                www.digital-mft.com</div>
        </div>
        <div class="part5-content" v-if="false">
            <div v-for="(item, index) of list" :key="index" class="cell">
                <div class="question">
                    {{ $t(item.qKey) }}
                </div>
                <div class="answer">
                    {{ $t(item.aKey) }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.part5 {
    .c477 {
        width: 185px;
        height: 185px;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .concat-item {
        display: flex;
        margin-bottom: 26px;
        margin-left: 40px;
        .c-name {
            font-weight: bold;
            font-size: 11px;
            color: #00F1FB;
            margin-right: 16px;
        }
        .c-text {
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
        }
    }
    .part5-content {
        padding: 20px;
        .cell {
            width: 100%;
            min-height: 200px;
            margin-bottom: 20px;
            padding: 35px 28px;
            background-image: url('~@/assets/img/Home/Group 1321316810@2x.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            text-align: left;

            .question {
                font-weight: 500;
                font-size: 20px;
                color: #4986FF;
                line-height: 30px;
                margin-bottom: 20px;
                font-weight: 700;
            }
            .answer {
                font-weight: 500;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 30px;
            }
        }
    }
}
</style>

<script>
import HomeTile from '@/components/HomeTitile.vue'

export default {
    components: {
        HomeTile
    },
    data() {
        return {
            list: [{
                q: '为什么要开发ETF公链',
                a: '为了让ETF在链上执行，这样会更公开和公平',
                qKey: 'guanwang1.us22',
                aKey: 'guanwang1.us23'
            },{
                q: 'ETF可以在其他公链执行吗',
                a: '可以实现的，不过由于公链的机制特性，会被节点套利',
                qKey: 'guanwang1.us24',
                aKey: 'guanwang1.us25'
            },{
                q: 'ETF公链和EVM公链的区别',
                a: '修改了GAS机制，保证节点无法提前排队，让金融更安全',
                qKey: 'guanwang1.us26',
                aKey: 'guanwang1.us27'
            },{
                q: 'ETF一直没在公链执行的原因',
                a: '目前EVM的排队机制，可以让节点在内网进行排队，这样就可以100%套利',
                qKey: 'guanwang1.us28',
                aKey: 'guanwang1.us29'
            }]
        }
    }
}
</script>