import store from '@/store'
import { ethers } from 'ethers'
import contractToken from './contract'
import approves from './approve'


// console.log('当前合约地址', contractToken.address);

const userChainId = store.state.userChainId
const userChainRPC = store.state.userChainRPC

const GetWalletAddress = () => {
  return new Promise(async resolve => {


    if (typeof window.ethereum !== "undefined") {
      let addr = await ethereum.request({ method: "eth_requestAccounts" }); //授权连接钱包
      // console.log("用户钱包地址:", addr[0]);
      resolve(addr[0])
    } else {
      // alert("未安装钱包插件！");
    }
  })

}

/* 获取当前网络ID */
const GetCurrentChainId = () => {
  return new Promise(async resolve => {

    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      resolve(network)
    } else {
      // alert("未安装钱包插件！");
    }
  })

}


/* 切换到用户网络 */
const CutUserInternet = () => {
  return new Promise(async resolve => {
    if (GetCurrentChainId().chainId == userChainId) {
      resolve(true)
    } else {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ethers.utils.hexValue(userChainId), rpcUrls: [userChainRPC] }],
        });
        resolve(true)
      } catch(error) {
        resolve(false)
      }
    }
  })

}



const checkAndSwitchNetwork = async () => {
  if (window.ethereum) {
    try {
      // 获取当前链 ID
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
      
      // 获取当前 RPC 地址
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const currentRpcUrl = provider.connection.url;

      // 检查是否已在目标 RPC 地址和链 ID
      if (currentRpcUrl === userChainRPC && currentChainId === userChainId) {
        console.log("已经连接到目标 RPC 地址，无需切换。");
        return;
      }

      // 提示用户切换到指定网络
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: ethers.utils.hexValue(userChainId),
            chainName: 'Binance Smart Chain Mainnet',
            rpcUrls: [userChainRPC], // 确保拼写正确
            blockExplorerUrls: ['https://bscscan.com'],
            nativeCurrency: {
              name: 'Binance Coin',
              symbol: 'BNB',
              decimals: 18
            }
          }
        ],
      });
      console.log("网络已切换到指定 RPC 地址。");
    } catch (error) {
      console.error("切换网络失败", error);
    }
  } else {
    console.log("请安装 MetaMask");
  }
};



// 质押
const abi = async (data, callback) => {

  if (!window.ethereum) return
  await window.ethereum.enable()

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const useAccount = await signer.getAddress()


  // // console.log(omg_contract.methods);

  // const bamount = (ethers.BigNumber.from(Number(data).toFixed(0))).mul('1000000000000000000')

  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address
  
  if (data.type === 'check') {
    const lp = new ethers.Contract(
      contractTokenAdress,
      contractToken.abi,
      signer
    )
    // console.log(data)
    try {
      await lp[data.abiName](...(data.params || [])).then(res => {
        // console.log(res);
        callback({
          code: 200,
          message: '',
          data: res
        })
      })
    } catch (err) {
      // console.log(`请求失败${data.abiName}`, err);
      callback({
        code: 401,
        message: '取消操作'
      })
    }
  } else {
    /**
     * 
     * 200 成功
     * 
     * 401 取消操作
     * 
     * 400 操作失败
     */
    // alert('暂未开放!')
    // return 


    if (!await CutUserInternet()) {
      callback({
        code: 401,
        message: '取消操作'
      })
      return
    }

    // console.log(123)

    var Web3 = require('web3');
    var web3 = new Web3(Web3.givenProvider)
    const omg_contract = new web3.eth.Contract(contractToken.abi, contractTokenAdress)

    let sendData = {
      from: useAccount
    }

    if (data.sendValue) sendData['value'] = data.sendValue;
    // console.log(data.params);

    omg_contract.methods[data.abiName](...data.params || []).send(sendData)
      .on('transactionHash', function (hash) {
        // // console.log('hash', hash);
      })
      .on('receipt', function (receipt) {
        // console.log('receipt', receipt);
        callback({
          code: 200,
          data: receipt,
          message: ''
        })
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // // console.log('confirmationNumber', confirmationNumber);
        // // console.log('receipt', receipt);
      })
      .on('error', function (err) {
        // console.log(err)
        callback({
          code: 401,
          message: '取消操作'
        })
      });
  }

}

// usdt授权
const approveUSDT = async (params, callback) => {
  if (!window.ethereum) return
  await window.ethereum.enable()

  var Web3 = require('web3');
  var web3 = new Web3(Web3.givenProvider)

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()

  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address

  const to = contractTokenAdress;

  // console.log('参数', params);

  const bamount = (ethers.BigNumber.from(params.number)).mul('1000000000000000000')

  // console.log('我的地址', useAccount);
  // console.log('授权USDT地址', params.address);
  // console.log('代币地址', contractTokenAdress);
  
  const omg_contract = new web3.eth.Contract(approves.abi, params.address)
  omg_contract.methods.approve(to, bamount).send({
    from: useAccount
  })
    .on('receipt', function (receipt) {
      // console.log('receipt', receipt);
      callback(receipt.transactionHash)
    })
    .on('error', function (err) {
      // console.log(err)
      callback(false)
    });
}


// 检测是否授权

const allowanceUSDT = async (approvesAddress) => {

  if (!window.ethereum) return
  await window.ethereum.enable()

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()


  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address

  const lp = new ethers.Contract(
    approvesAddress,
    approves.abi,
    signer
  )
  return new Promise(async (resolve, reject) => {
    try {
      await lp.allowance(useAccount, contractTokenAdress).then(res => {
        // console.log(Number(res));
        resolve(res)
      })
    } catch (err) {
      // console.log(err);
      reject(false)
    }
  })
}

// token授权
const approveToken = async (params, callback) => {
  if (!window.ethereum) return
  await window.ethereum.enable()

  var Web3 = require('web3');
  var web3 = new Web3(Web3.givenProvider)

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()

  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address

  const to = contractTokenAdress;
  const bamount = (ethers.BigNumber.from(999999999999999)).mul('1000000000000000000')

  const omg_contract = new web3.eth.Contract(approves.abi, params.address)
  omg_contract.methods.approve(to, bamount).send({
    from: useAccount
  })
    .on('receipt', function (receipt) {
      // console.log('receipt', receipt);
      callback(receipt.transactionHash)
    })
    .on('error', function (err) {
      // console.log(err)
      callback(false)
    });
}


export {

  GetWalletAddress,
  GetCurrentChainId,
  checkAndSwitchNetwork,
  approveUSDT,
  allowanceUSDT,
  approveToken,
  abi,

  ethers
}